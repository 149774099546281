import ParentModel from '@/models/parent/ParentModel.js';
import { auth } from '@/main';

export default class User extends ParentModel {
   constructor(data) {
      super(data);
      if (data != null) {
         this.company_id = data.company_id;
         this.role = data.role;
         this.first_name = data.first_name;
         this.last_name = data.last_name;
         this.email = data.email;
         this.phone = data.phone;
      } else {
         this.company_id = auth.user.company_id;
         this.role = 'Viewer';
         this.first_name = '';
         this.last_name = '';
         this.email = '';
         this.phone = '';
      }
   }

   get toDto() {
      const dto = {};
      dto.company_id = this.company_id;
      dto.role = this.role;
      dto.first_name = this.first_name;
      dto.last_name = this.last_name;
      dto.email = this.email;
      dto.phone = this.phone;
      return dto;
   }
}
