<template>
   <div class="px-5 app-container v-sheet v-sheet--shaped mt-4">
      <v-btn color="primary" class="ma-4 mb-8 ml-0 pl-0" width="200" x-large @click="createItem">
         <span class="text-caption text-md-left font-weight-medium pl-3">
            Invite a new user
         </span>
         <v-icon class="pl-16" right>
            mdi-plus-circle-outline
         </v-icon>
      </v-btn>
      <v-card width="1040px">
         <v-data-table :headers="headers" :items="items" class="elevation-2 rounded" sort-by="id" sort-desc rounded :header-props="{ sortIcon: 'mdi-chevron-down' }">
            <template v-slot:item.actions="{ item }">
               <v-btn plain text x-small color="secondary" class="ma-0 pa-1" @click="editItem(item)">
                  <v-icon x-small>
                     mdi-pencil-outline
                  </v-icon>
                  Edit
               </v-btn>
               <v-btn plain text x-small color="secondary" class="ma-0 pa-1" @click="deleteItem(item)">
                  <v-icon x-small>
                     mdi-close-circle-outline
                  </v-icon>
                  Remove
               </v-btn>
            </template>
         </v-data-table>
      </v-card>
      <v-slide-x-reverse-transition>
         <FormUser v-if="item" :item.sync="item" @updated="reset" />
      </v-slide-x-reverse-transition>
   </div>
</template>
<script>
import User from '@/models/User.js';
import UserService from '@/service/UserService.js';
import FormUser from '@/components/Forms/FormUser.vue';
import util from '@/utils';

export default {
   components: {
      FormUser,
   },
   data() {
      return {
         headers: [
            { text: 'ID', value: 'id', sortable: true },
            { text: 'First Name', align: 'start', sortable: true, value: 'first_name' },
            { text: 'Last Name', align: 'start', sortable: true, value: 'last_name' },
            { text: 'EMail', value: 'email', sortable: false },
            { text: 'Phone', value: 'phone', sortable: false },
            { text: 'Role', value: 'role', sortable: true },
            { text: 'Actions', value: 'actions', sortable: false },
         ],
         total: 0,
         item: null,
         items: [],
      };
   },

   mounted() {
      this.callServiceList();
   },

   methods: {
      reset() {
         this.item = null;
         this.callServiceList();
      },
      createItem() {
         this.item = new User();
      },
      editItem(item) {
         this.item = new User(item);
      },
      deleteItem(item) {
         util.showConfirm(`Delete ${item.first_name} ${item.last_name}`,
            `Are you sure you want to delete the user ${item.first_name} ${item.last_name}?`,
            () => this.deleteTheUser(item),
            () => this.reset());
      },
      deleteTheUser(item) {
         const userService = new UserService(this);
         userService.delete(item.id).then(() => {
            this.reset();
         });
      },
      callServiceList() {
         util.loading(1);
         const userService = new UserService(this);
         userService.list().then((list) => {
            if (list) {
               this.items = list.data;
               this.total = list.data.length;
            }
            util.loading(false);
         });
      },
   },
};
</script>
