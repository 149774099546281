<template>
   <div class="sideForm">
      <v-card>
         <v-card-title>
            {{ (!selfItem.hasId) ? 'Invite a new user' : 'Edit user' }}
         </v-card-title>
         <v-card>
            <v-icon class="closeBTN" @click="closeForm"> mdi-close </v-icon>
         </v-card>
      </v-card>
      <v-divider></v-divider>
      <v-form ref="form" v-model="valid" lazy-validation >
         <v-text-field
            v-if="selfItem.hasId"
            v-model="selfItem.first_name"
            label="First Name"
            color="primary"
            persistent-placeholder
            :rules="[ v=> util.rule('name').test(v) || 'Enter your first name' ]"
            required
         >
         </v-text-field>
         <v-text-field
            v-if="selfItem.hasId"
            v-model="selfItem.last_name"
            label="Last Name"
            color="primary"
            persistent-placeholder
            :rules="[ v=> util.rule('name').test(v) || 'Enter your last name' ]"
            required
         >
         </v-text-field>
         <v-text-field
            v-model="selfItem.email"
            label="EMail"
            :rules="[ v=> util.rule('email').test(v) || 'EMail must be valid' ]"
            persistent-placeholder
            required
         ></v-text-field>
         <v-select
            v-model="selfItem.role"
            :items="roles"
            label="Role"
            :rules="[v => !!v || 'Item is required']"
            persistent-placeholder
            required
         ></v-select>
         <v-text-field
            v-if="selfItem.hasId"
            v-model="selfItem.phone"
            label="Phone"
            persistent-placeholder
            :rules="[ v=> util.rule('phone').test(v) || 'Enter your phone' ]"
            required
         ></v-text-field>
         <v-card class="formFooterButtons">
            <v-btn :disabled="!valid" color="info" @click="submit">Apply
               <v-icon
                  right
               >
                  mdi-check-bold
               </v-icon>
            </v-btn>
         </v-card>
      </v-form>
   </div>

</template>
<script>
import Util from '@/utils';
import UserService from '@/service/UserService.js';

export default {
   name: 'FormUser',
   props: ['item'],
   data() {
      return {
         util: Util,
         service: new UserService(),
         selfItem: this.item,
         valid: false,
         roles: ['owner', 'writer', 'viewer'],
      };
   },
   mounted() {
      console.log(this.selfItem);
   },
   methods: {
      submit() {
         if (this.$refs.form.validate()) {
            Util.loading(true);
            // is item exists and need to be updated?
            if (this.selfItem.hasId) {
               this.service.update(this.selfItem.id, this.selfItem).then((res) => {
                  console.log('update res', res);
                  Util.loading(false);
                  if (res.ok) {
                     //this.$mixpanel.track('User updated', { res});
                     Util.sendEvent('User', 'update', 'success', res);
                     Util.showMessage('update sucessfuly!'); 
                  } else {
                     //this.$mixpanel.track('User update error', { res});
                     Util.sendEvent('User', 'update', 'error', res);
                     Util.showMessage(`error updaing: ${res.data.errors}`, 'error');
                  }
               });
            } else {
               this.service.add(this.selfItem).then((res) => {
                  Util.loading(false);
                  console.log('add res:', res);
                  if (res.ok) {
                     //this.$mixpanel.track('User added', {res});
                     Util.sendEvent('User', 'add', 'success', res);
                     Util.showMessage('added sucessfuly!');
                  } else {
                     //this.$mixpanel.track('User add error', { res});
                     Util.sendEvent('User', 'add', 'error', res);
                     Util.showMessage(`error adding: ${res.data.errors}`, 'error');
                  }
               });
            }
            this.$emit('updated', null);
         }
      },
      clear() {
         this.$refs.form.reset();
      },
      closeForm() {
         this.$emit('update:item', null);
         this.selfItem = null;
      },
   },
};
</script>
